import React from 'react';
import NavBar from './components/navBar/navBar';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/home/home';
import Footer from './components/footer/footer';
import Rent from './pages/rent/rent';
import Buy from './pages/buy/buy';
import AgentProfile from './pages/agentProfile/agentProfile';
import Explore from './pages/explore/explore';
import Property from './pages/Property/property';
import Agents from './pages/agents/agents';
import Companies from './pages/companies/companies';
import { LayoutWrapper } from './components/layoutWrapper/layoutWrapper';
import ComingSoon from './pages/landingPage/landingPage';
import PrivacyPolicy from './pages/privacyPolicy/privacyPolicy';

function App() {

  return (
    <Router>
      {/* <LayoutWrapper>
        <Routes>
          <Route path='/properties' element={<Explore />} />
          <Route path='/companies' element={<Companies />} />
          <Route path='/blogs' element={<Home />} />
          <Route path='/agents' element={<Agents />} />
          <Route path='/props/:area' element={<Explore />} />
          <Route path='/agentProfile/:id' element={<AgentProfile />} />
          <Route path='/property/:id' element={<Property />} />
          <Route path='/' element={<Home />} />
        </Routes>
      </LayoutWrapper> */}
      <Routes>
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/' element={<ComingSoon />} />
      </Routes>
    </Router>
  );
}

export default App;
